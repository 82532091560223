import { Canvas } from '@react-three/fiber';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { MainInfo } from './components/Home/MainInfo';
import { Cards } from './components/Home/Canvas';
import { CardsSmall } from './components/Home/CanvasSmall'
import { CardsMobile } from './components/Home/CanvasMobile';
import { Loader } from './components/Home/Loading';


import { Academy } from './components/Academy/Academy';
import { Foundation } from './components/Foundation/Foundation'
import { About } from './components/About/About'
import { Labs } from './components/Labs/Labs';
import { Pass } from './components/Pass/Pass';
import { Token } from './components/Token/Token';
import { Waifus } from './components/Games/Waifus/Waifus';
import { CursedMansion } from './components/Games/CursedMansion/CursedMansion';
import { CyberSkies } from './components/Games/CyberSkies/CyberSkies';
import { ChimbaRacing } from './components/Games/Racing/Racing';
import { Cosmic } from './components/Games/Cosmic/Cosmic';
import {LandingPage} from './components/LandingPage'


const DeckPDF = () => {
  
  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <iframe 
        src="/ChimbaDeck.pdf" 
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Chimba Deck"
      />
    </div>
  );
};
export const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 800;
  const isSmall = screenWidth <= 1440;

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<>
            <Canvas fallback={null} style={{ position: 'absolute' }}>
              <Loader />
            </Canvas>
            <MainInfo/>
            {isMobile ? (
              <CardsMobile />
            ) : isSmall ? (
              <CardsSmall />
            ) : (
              <Cards />
            )}
          </>} />
          
          <Route path="/testingNewHome" element={<><LandingPage /></>} />
          <Route path="/academy" element={<><Academy /></>} />
          <Route path="/foundation" element={<><Foundation /></>} />
          <Route path="/about" element={<><About /></>} />
          <Route path="/labs" element={<><Labs /></>} />
          <Route path="/pass" element={<><Pass /></>} />
          <Route path="/token" element={<><Token /></>} />

          <Route path="/quantum-breach" element={<><Waifus /></>} />
          <Route path="/cursed-mansion" element={<><CursedMansion /></>} />
          <Route path="/cyber-skies" element={<><CyberSkies /></>} />
          <Route path="/chimba-racing" element={<><ChimbaRacing /></>} />
          <Route path="/cosmic-labs" element={<><Cosmic /></>} />

          <Route path="/deck" element={<DeckPDF />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
